<template>
  <title-button-wrapper :to="goBack" :title="$t('components.wizard.wizardItemDetails.title')">
    <v-row>
      <v-col cols="12" sm="9">
        <v-container class="white pa-6" v-if="!loading">
          <product-description :product="product"></product-description>
          <download-product-specification
            :pdfSpecification="getSpecificationByLang(product, selectedLanguage, true)"
          ></download-product-specification>
          <product-technical-data :attributes="product.attributes"></product-technical-data>
          <v-divider class="mt-4"></v-divider>
          <v-row>
            <product-stock-info
              :supply="product.supply"
              :stock="product.stock"
            ></product-stock-info>
            <v-spacer></v-spacer>
            <v-col class="d-flex align-center justify-end">
              <span class="mr-4"
                >{{ formatCurrency(productUnitPrice) }} ({{ $t('helpers.net') }}) /
                {{ $t('helpers.piecesAmount') }}</span
              >
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else
          ><v-row> <v-progress-linear indeterminate></v-progress-linear></v-row
        ></v-container>
      </v-col>
    </v-row>
  </title-button-wrapper>
</template>
<script>
import TitleButtonWrapper from '../../components/TitleButtonWrapper.vue';
import ProductService from '../../services/ProductService';
import ProductDescription from '../../components/ProductDescription.vue';
import DownloadProductSpecification from '../../components/DownloadProductSpecification.vue';
import ProductTechnicalData from '../../components/ProductTechnicalData.vue';
import ProductStockInfo from '../../components/ProductStockInfo.vue';
import { groupPriceMixin } from '../../mixins/groupPriceMixin.js';
import { formatCurrency } from '../../mixins/formatCurrency';
import { getNameByLanguage } from '../../mixins/getNameByLanguage';

export default {
  name: 'WizardItemDetails',
  mixins: [groupPriceMixin, formatCurrency, getNameByLanguage],
  components: {
    TitleButtonWrapper,
    ProductDescription,
    DownloadProductSpecification,
    ProductTechnicalData,
    ProductStockInfo,
  },
  data() {
    return {
      product: {},
      quantity: 1,
      loading: true,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetchProduct() {
      try {
        const response = await ProductService.get(this.productId);
        this.product = response.data;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    isStockAvailable() {
      return this.product.stock > 0;
    },
  },
  activated() {
    const productId = this.$route.params.productId;
    this.productId = productId;
    this.fetchProduct();
  },
};
</script>
